.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}
label {
  width: 350px;
  box-sizing: border-box;
}
.success {
  font-size: 20px;
  color: green;
}
.error {
  font-size: 20px;
  color: red;
}

.modal {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  position: relative;
  outline: none;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000070;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  position: fixed;
}

.modal__close {
  position: absolute;
  top: -45px;
  right: -45px;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
}
.modal__close svg{
  max-width: 100%;
  max-height: 100%;
  fill: #fff;
}